import React, { useEffect, useState } from "react";
import {globalHistory, Location} from '@reach/router'
import { useQueryParam, NumberParam, StringParam, QueryParamProvider } from "use-query-params";
import api from "@api";
import { authState } from "@state/auth";
import { ESteps, stepState } from "@state/step";
import { useRecoilState, useSetRecoilState } from "recoil";

import IllusCloud1 from "@illustrations/login/cloud1.svg";
import IllusCloud2 from "@illustrations/login/cloud2.svg";
import IllusCity from "@illustrations/login/city.svg";
import IllusTruck from "@illustrations/login/truck.svg";
import Layout from "@components/layouts/Layout";
import { Buffer } from "buffer";
import { Title2Bold, Title3Bold } from "@metyis-ds/typography";
import { Router, RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby"

export const Join = () => {
    return (
      <Router>
        <JoinGame path="/join" />
      </Router>
    );
  };
export const JoinGame: React.FC<RouteComponentProps> = ({ location }) => {

    const setStep = useSetRecoilState(stepState);
    const setAuth = useSetRecoilState(authState);  
    const [auth] = useRecoilState(authState);

    const [error, setError] = useState<any>();

    const login = async (gameId: string, userId: string, code: string) => {
        try {
            const { data, status } = await api.postJoinGame({
              gameid: gameId.toUpperCase(),
              userid: userId,
              code: Buffer.from(code).toString("base64")
            });
            if (status === 200) {
                setStep(data.currentStep as ESteps);
                setAuth({ gameid: gameId.toUpperCase(), userid: userId, random: data.currentRandom });
                navigate("/game")
            }
        } catch(err) {
            console.log("ERRORR LOGING IN", err)
            setError(err.error.error);
        }
    }

    useEffect(() => {
        if(location) {
            const params = new URLSearchParams(location.search);
            const parameter1 = params.get("json");
            
            if(parameter1) {
                const params = JSON.parse(decodeURIComponent(parameter1));
                if(params.gameId && params.userId && params.code) {
                    if(auth.gameid == params.gameId && auth.userid == params.userId) {
                        api.postCheckGame({
                            gameid: auth.gameid,
                            userid: auth.userid,
                            random: auth.random
                        })
                        .then(({ data }) => {
                            navigate("/game")
                        }).catch(err => {
                            login(params.gameId, params.userId, params.code)
                        })
                    } else {
                        login(params.gameId, params.userId, params.code)
                    }
                }
            } else {
                setError("No Parameters detected")
            }
        }
    }, [location])
    return error ? <Layout division="full">
        <div className="relative bg-sky-gradient overflow-hidden ">
            <IllusCity className="absolute w-auto bottom-0 right-0 h-3/5" />
            <IllusTruck className="absolute bottom-0 left-0" />
            <IllusCloud1 className="absolute w-auto h-auto top-20 right-8" />
            <IllusCloud2 className="absolute w-auto h-auto  top-60 left-12" />
            <div className="w-full h-full flex justify-center items-center">
                <Title3Bold>{error}</Title3Bold>
            </div>
        </div>
    </Layout>
    : <Layout division="full">
    <div className="relative bg-sky-gradient overflow-hidden ">
        <IllusCity className="absolute w-auto bottom-0 right-0 h-3/5" />
        <IllusTruck className="absolute bottom-0 left-0" />
        <IllusCloud1 className="absolute w-auto h-auto top-20 right-8" />
        <IllusCloud2 className="absolute w-auto h-auto  top-60 left-12" />
        <div className="w-full h-full flex justify-center items-center">
            <Title3Bold>Joining Game...</Title3Bold>
        </div>
    </div>
</Layout>
}

export default Join;