import React from "react";
import classNames from "classnames";
import "@components/layouts/Layout.css";

interface ILayoutProps {
  division?: "full" | "half" | "third";
  className?: string;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  division = "third",
  className
}) => {
  let gridTemplateClasses =
    division === "half"
      ? "grid-cols-2"
      : division === "full"
      ? "grid-cols-1"
      : "grid-cols-base";

  return (
    <main
      className={classNames(
        "grid grid-flow-col overflow-hidden h-screen",
        gridTemplateClasses,
        className
      )}
    >
      {children}
    </main>
  );
};

export default Layout;
